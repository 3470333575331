












































































































  .app-file-field {
    &.v-text-field--outlined {
      .v-input__slot {
        fieldset {
          border-style: dashed;
          border-width: 1.5px;
        }
      }
    }

    .v-input__slot {
      padding: 0 !important;
      overflow: hidden;
      background-color: var(--v-secondaryBackgroundColor-base) !important;

      .v-input__prepend-inner {
        width: 100%;
        height: var(--height, 100%);
        margin: 0;
        padding: 10px;
        justify-content: center;
        align-items: center;

        .file-holder {
          display: flex;
          align-items: center;
          z-index: 0;

          .top {
            z-index: 1;
          }
        }

        .v-image {
          margin: 0 auto;
          max-width: 100%;
          width: var(--width, 100%);
          height: var(--height, 100%);

          .v-image__image {
            background-size: var(--background-size, contain);
          }
        }

        .image-placeholder {
          margin: 0 auto;
          max-width: 100%;
          width: var(--width, 100%);
          height: var(--height, 70px);
          background-color: #F7F9FD;
          border: 1px dashed #D2D6E2;
        }

        .action {
          position: relative;
          z-index: 1;
        }

        .v-icon {
          font-size: 50px;
        }
      }

      .v-text-field__slot {
        position: absolute !important;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;

        input {
          max-height: initial;
          width: 100%;
          height: 100%;
          padding: 0;
          cursor: pointer;
        }
      }
    }
  }
